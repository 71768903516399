export const TabTypes = {
  Eload: 1,
  Data: 2,
};

export const EloadType = {
  GlobeEload: 10,
  SmartEload: 20,
  SunEload: 40,
  DitoEload: 50,
};

export const DataType = {
  GlobeData: 11,
  SmartData: 21,
  SunData: 41,
  DitoData: 51,
};

export const AllProductsType = [
  EloadType.GlobeEload,
  EloadType.SmartEload,
  EloadType.SunEload,
  EloadType.DitoEload,
  DataType.GlobeData,
  DataType.SmartData,
  DataType.SunData,
  DataType.DitoData,
];

export const headerType = {
  Load: 1,
  Box: 2,
};

export const headerTypes = [
  {
    id: 1,
    name: "话费充值",
    url: require("@/assets/load.svg"),
  },
  {
    id: 2,
    name: "盒子充值",
    url: require("@/assets/wifi.svg"),
  },
];
