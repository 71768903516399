import { createStore } from "vuex";
import { CreateOrderV2 } from "@/modules/details/entity";
import createPersistedState from "vuex-persistedstate";

export default createStore({
  plugins: [
    createPersistedState({
      paths: ["createOrderInfo", "createOrderInfoBox"],
      storage: window.sessionStorage,
    }),
  ],
  state: {
    currentProduct: {
      price: 0,
    },
    phone: "",
    boxPhone: "",
    createOrderInfo: new CreateOrderV2(),
  },
  getters: {},
  mutations: {
    CHANGEPRODUCT: (state, parmas) => {
      state.currentProduct = parmas;
    },
    UPDATEPHONE: (state, phone) => {
      state.phone = phone;
    },
    UPDATEBOXPHONE: (state, phone) => {
      state.boxPhone = phone;
    },
    updateCreateOrderInfo: (state, info) => {
      state.createOrderInfo = info;
    },
  },
  actions: {},
  modules: {},
});
