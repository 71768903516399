import { request } from "../request";

class SystemRepository {
  static queryJssdk(url: string, platform: number) {
    return request({
      url: "/api/mm/wx/web/getJssdk",
      method: "get",
      params: {
        url,
        loginLocation: platform,
      },
    });
  }

  static queryToken(code: string, platform: number) {
    return request({
      url: `/api/ucenter/member/login?code=${code}&&memberChannel=${platform}`,
      method: "post",
    });
  }
}

export default SystemRepository;
